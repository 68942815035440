exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-index-js": () => import("./../../../src/pages/company/index.js" /* webpackChunkName: "component---src-pages-company-index-js" */),
  "component---src-pages-contact-alliance-alliance-complete-js": () => import("./../../../src/pages/contact/alliance/alliance_complete.js" /* webpackChunkName: "component---src-pages-contact-alliance-alliance-complete-js" */),
  "component---src-pages-contact-alliance-index-js": () => import("./../../../src/pages/contact/alliance/index.js" /* webpackChunkName: "component---src-pages-contact-alliance-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-partner-index-js": () => import("./../../../src/pages/contact/partner/index.js" /* webpackChunkName: "component---src-pages-contact-partner-index-js" */),
  "component---src-pages-contact-partner-partner-complete-js": () => import("./../../../src/pages/contact/partner/partner_complete.js" /* webpackChunkName: "component---src-pages-contact-partner-partner-complete-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-news-wp-post-database-id-js": () => import("./../../../src/pages/news/{wpPost.databaseId}.js" /* webpackChunkName: "component---src-pages-news-wp-post-database-id-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-service-index-js": () => import("./../../../src/pages/service/index.js" /* webpackChunkName: "component---src-pages-service-index-js" */),
  "component---src-pages-works-index-js": () => import("./../../../src/pages/works/index.js" /* webpackChunkName: "component---src-pages-works-index-js" */)
}

